<template src="./news.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "News",
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      fields: [
        {
          key: 'date',
          label: 'Datum',
          formatter: value => { return this.$options.filters.formatDate(value) },
        },
        { key: 'title', label: 'Titel'},
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      news: [],
      meta: {
        current_page: 1,
        per_page: 15
      }
    }
  },
  methods: {
    loadNews(ctx, callback) {
      let self = this;
      const params = '?page=' + ctx.currentPage + '&per_page=' + ctx.perPage;
      Http.request('GET', '/news' + params).then(function (news) {
        self.meta = news.meta;
        self.news = news.data;
        callback(news.data)
      });
      return null
    },
    setNewsActive: function (news) {
      this.$bvModal.msgBoxConfirm('Möchtest du diese News ' + (news.active ? 'offline' : 'online') + ' stellen?', {
          headerBgVariant: 'primary',
          title: 'News de/aktivieren?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer === true){
            let active = news.active ? 0 : 1;
            Http.request('POST', '/news/' + news.id + '/active/' + active).then(() => {
              news.active = active === 1;
            });
          }
        });
    },
    deleteNews: function (news) {
      let self = this;
      let confirmHtml = self.$createElement('div', { domProps: {
        innerHTML: 'Möchtest du diese News wirklich löschen?<br>Bitte beachte, dass ggf. angelegte Social Media Posts automatisch mit gelöscht werden.'
      }});
      self.$bvModal.msgBoxConfirm([confirmHtml], {
          headerBgVariant: 'primary',
          title: 'News löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/news/' + news.id).then(() => {
              if(
                typeof(self.$refs.newsTable) !== "undefined" &&
                typeof(self.$refs.newsTable.refresh) === "function"
              ){
                self.$refs.newsTable.refresh();
              }
              else{
                self.$router.push('/website/news');
              }
            });
          }
        });
    }
  }
}

</script>

<style scoped></style>
