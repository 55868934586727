var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-4 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"9"}},[_c('h1',[_vm._v(_vm._s(_vm.news.title))])]),(_vm.news.id > 0)?_c('b-col',{staticClass:"text-lg-right",attrs:{"cols":"12","lg":"3"}},[_c('b-button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:(_vm.news.active ? 'News offline stellen' : 'News online stellen'),expression:"news.active ? 'News offline stellen' : 'News online stellen'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"id":"setNewsActive","variant":_vm.news.active ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.setNewsActive(_vm.news)}}},[(_vm.news.active)?_c('i',{staticClass:"fa fa-eye-slash"}):_c('i',{staticClass:"fa fa-eye"})]),_vm._v("   "),_c('b-button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:('Löschen'),expression:"'Löschen'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"id":"deleteNews","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteNews(_vm.news)}}},[_c('i',{staticClass:"fa fa-trash"})])],1):_vm._e()],1),(_vm.news.id > 0)?_c('h2',[_vm._v("News bearbeiten")]):_c('h2',[_vm._v("News hinzufügen")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-row',{staticClass:"mb-3 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Datum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"date"}},[_vm._v("Datum")]),_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"date","id":"date","placeholder":"Datum auswählen"},model:{value:(_vm.news.date),callback:function ($$v) {_vm.$set(_vm.news, "date", $$v)},expression:"news.date"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Titel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v("Titel*")]),_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","id":"title","placeholder":"Titel"},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"image","name":"Bild"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"picture"}},[_vm._v("Bild hochladen (jpg, jpeg)")]),_c('b-form-file',{attrs:{"id":"picture","accept":".jpg,.jpeg","placeholder":"Datei auswählen...","drop-placeholder":"Datei hierher ziehen..."},on:{"change":function($event){_vm.onFileChange($event) || validate($event)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4","offset-lg":"8"}},[_c('image-rotate',{ref:"imageRotate",attrs:{"src":_vm.news.picture}})],1)],1),_c('hr'),_c('p',[_vm._v("News Text:")]),_c('tinymce-editor',{attrs:{"api-key":_vm.tinymce.key,"init":_vm.tinymce.defaultOptions},model:{value:(_vm.news.text),callback:function ($$v) {_vm.$set(_vm.news, "text", $$v)},expression:"news.text"}}),_c('br'),_c('p',{staticClass:"text-right mb-0"},[_vm._v("mit * gekennzeichnete Felder sind Pflichtfelder")])],1),_c('h2',[_vm._v("Posts anlegen")]),((_vm.$route.params.id === 'null' || _vm.news.id > 0) && _vm.permissionCheck('facebook'))?_c('social-post-form',{ref:"socialPosts",attrs:{"linkable":{type: 'news', data: _vm.news}}}):_vm._e(),_c('router-link',{attrs:{"to":"/website/news"}},[_c('b-button',{attrs:{"variant":"outline-danger"}},[_vm._v("Abbrechen")])],1),_c('div',{staticClass:"primary-action"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid,"id":"save","size":"lg","pill":""},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" Speichern ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }